import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./style.scss";

import Wrapper from "../Wrapper";
import TCaption from "../TCaption";

const htmlReactParser = require("html-react-parser");
const parseHTML = htmlReactParser.default;

function SeoText(props) {
  const location = useLocation();
  const [seotext, setSeotext] = useState(false);
  useEffect(() => {
    if (!seotext) {
      fetch(
        `https://casada-russia.ru/api/casada-mobile/seo-text/?path=${location.pathname}`
      )
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            return res.json();
          } else {
            let error = new Error(res.statusText);
            error.response = res;
            throw error;
          }
        })
        .then((result) => {
          if (!!result) {
            setSeotext(result);
          }
        })
        .catch((e) => {
          console.log("Error: " + e.message);
          console.log(e.response);
        });
    }
  });

  if (!!seotext) {
    const img = !!seotext.img ? seotext.img : "";
    const info = {
      classList: "info",
      style: {},
      title: {
        classList: "",
        style: {},
        value: "",
      },
      text: {
        classList: "",
        style: {},
        value: "",
      },
    };
    if (!!seotext.info) {
      if (!!seotext.info.classList)
        info.classList += ` ${seotext.info.classList}`;
      if (!!seotext.info.style) info.style = seotext.info.style;
      if (!!seotext.info.title) {
        if (!!seotext.info.title.classList)
          info.title.classList = seotext.info.title.classList;
        if (!!seotext.info.title.style)
          info.title.style = seotext.info.title.style;
        if (!!seotext.info.title.value)
          info.title.value = parseHTML(seotext.info.title.value);
      }
      if (!!seotext.info.text) {
        if (!!seotext.info.text.classList)
          info.text.classList = seotext.info.text.classList;
        if (!!seotext.info.text.style)
          info.text.style = seotext.info.text.style;
        if (!!seotext.info.text.value)
          info.text.value = parseHTML(seotext.info.text.value);
      }
    }
    return (
      <>
        <Wrapper bid="seo_text" backgroundColor="#F3F3F3" lazyLoad={true}>
          <TCaption.Spacer height="32px" />
          <div className={info.classList} style={info.style}>
            <h2 className={info.title.classList} style={info.title.style}>
              {info.title.value}
            </h2>
            <div className={info.text.classList} style={info.text.style}>
              {info.text.value}
            </div>
          </div>
          <div className="pic">
            <img
              src={`https://casada-russia.ru${img}`}
              width="300"
              height="300"
              alt={info.title.value}
            />
          </div>
          <TCaption.Spacer height="72px" />
        </Wrapper>
      </>
    );
  } else return <></>;
}
export default SeoText;
